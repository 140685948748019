@import '../../../../scss/theme-bootstrap';

.add-all-to-bag-formatter {
  position: relative;
  &__cta {
    padding: 10px;
    text-align: center;
    @include breakpoint($bp--xlarge-up) {
      text-align: initial;
      width: 100%;
      margin: 0;
       @include swap_direction(padding, 30px 10px 20px 25px);
    }
    .product-add-all-to-bag {
      &:not(mantle-cta--custom-textcolor) {
        &:focus {
          color: $color--white;
        }
      }
    }
  }
  &__content {
    margin: 0 auto;
    .content-block--grid {
      @include breakpoint($bp--xlarge-up) {
        padding-#{$ldirection}: 20px; 
      }
      &.content-block--slider {
        padding-bottom: 40px;
        @include breakpoint($bp--xlarge-up) {
          padding-bottom: 0;
        }
        .cr21-refresh {
          .content-block--grid-item.comparison-item {
            .product-brief__image {
              @include breakpoint($bp--medium-up) {
                margin: 10px auto 0;
                width: calc(70% + 40px);
              }
            }
            .product-brief__product-name {
              margin-top: 10px;
            }
            .product-brief__name-link {
              border: none;
            }
            .product-brief__price {
              .product-price-v1 {
                text-align: center;
              }
            }
            .product-brief__cta {
              @include breakpoint($bp--xlarge-up) {
                grid-gap: 12px;
                display: block;
                text-align: center;
              }
            }
            .product-brief__add-to-bag {
              padding-#{$rdirection}: 0;
              @include breakpoint($bp--medium-up) {
                max-width: none;
              }
              @include breakpoint($bp--xlarge-up) {
                display: inline-block;
                vertical-align: top;
                margin-top: 0;
                margin-bottom: 0;
                padding: 0 6px;
              }
            }
            .product-vto-link {
              padding-#{$ldirection}: 0;
              max-width: none;
              @include breakpoint($bp--xlarge-up) {
                display: inline-block;
                vertical-align: top;
                margin-top: 0;
                margin-bottom: 0;
                padding: 0 6px;
              }
            }
            .cta-vto-link {
              @include breakpoint($bp--xlarge-up) {
                padding: 0 15px;
                min-width: auto;
                position: relative;
                top: -1px;
              }
            }
            .product-add-to-bag {
              @include breakpoint($bp--xlarge-up) {
                padding: 0 15px;
                margin-top: 0;
                margin-bottom: 0;
              }
            }
            .product-brief__inventory-status {
              @include breakpoint($bp--xlarge-up) {
                display: block;
                padding: 0 10px;
              }
            }
            .product-inventory-status__list {
              @include breakpoint($bp--xlarge-up) {
                max-width: none;
                min-width: auto;
              }
            }
            .product-inventory-status__temp-oos-text,
            .product-inventory-status__item--preorder-noshop {
              min-width: auto;
            }
          }
        }
      }
    }
    .content-block-formatter {
      @include breakpoint($bp--xlarge-up) {
        margin: 0;
      }
    }
  }
  &__disclaimer {
    .mantle-custom-text {
      padding-#{$ldirection}: 20px;
      margin-top: 5px;
    }
  }
  &__cta {
    .button {
      display: inline-block;
    }
  }
}
